/*==========================
    App Showcase Area 
============================*/
.curve-wrapper {
    position: relative;
    .center_curve{
        transform: rotate(-180deg);
        position: absolute;
        width: 100%;
        left: 0;
        z-index: 1;
        line-height: 0;
        svg{
            height: 130px;
            fill: #f8f8f8;
            width: 100%;
        }

        &.bottom{
            bottom: 0;
        }
        &.top{
            transform: rotate(0);
            top: 0;
            svg{
                height: 120px;
                fill: #e3e9ff;
                width: 100%;
            }
        }

    }
    .space-height--1 {
        height: 122px;
    }
    .space-height--2 {
        height: 47px;
    }
}




.app-showcase-wrapper {
    background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,#F8F8F8),color-stop(50%,#ECEEFA),color-stop(100%,#E3E9FF));
    background: -moz-linear-gradient(180deg,#F8F8F8 0,#ECEEFA 50%,#E3E9FF 100%);
    background: -webkit-linear-gradient(180deg,#F8F8F8 0,#ECEEFA 50%,#E3E9FF 100%);
    background: -o-linear-gradient(180deg,#F8F8F8 0,#ECEEFA 50%,#E3E9FF 100%);
    background: -ms-linear-gradient(180deg,#F8F8F8 0,#ECEEFA 50%,#E3E9FF 100%);
    background: linear-gradient(180deg,#F8F8F8 0,#ECEEFA 50%,#E3E9FF 100%);
}

.showcase{
    &.showcase--1{
        .thumbnail{
            img{
                -moz-border-radius: 53px;
                -webkit-border-radius: 53px;
                border-radius: 53px;
                -moz-box-shadow: 0 15px 50px rgba(89,69,230,0.12);
                -webkit-box-shadow: 0 15px 50px rgba(89,69,230,0.12);
                box-shadow: 0 15px 50px rgba(89,69,230,0.12);
            }
        }
    }

    &.showcase--3 {
        .thumbnail{
            img{
                margin-left: -113px;
                @media #{$md-layout} {
                    margin-left: 0;
                }
                @media #{$sm-layout} {
                    margin-left: 0;
                }
            }
        }
    }


    &.showcase--2 {
        .list {
            margin-top: 32px;
        }
    }

    .showcase-inner{
        span{
            &.subtitle{
                color: #ee7455;
                font-size: 15px;
                font-weight: 600;
                letter-spacing: 3px;
                text-transform: uppercase;
                line-height: 1.34;
                margin-bottom: 24px;
                display: block;
            }
        }
        h3{
            &.title{
                font-size: 34px;
                line-height: 1.42;
                font-weight: 400;
                margin-bottom: 14px;
            }
        }
        p{
            font-size: 18px;
            line-height: 1.78;
            margin-bottom: 42px;
        }
    }
}

// App Download 
.app-download-inner { 
    .video-popup {
        &:hover {
            transform: inherit;
        }
    }
}