/*=======================
    Acoordion Style 
========================*/


.accodion-style--1 {
    .accordion__button {
        position: relative;
        font-size: 18px;
        line-height: 1.23;
        background: #fff;
        padding: 19px 30px;
        user-select: none;
        display: block;
        color: #6d70a6;
        cursor: pointer;
        outline: none;
        position: relative;
        z-index: 2;
        @extend %transition;
        padding-right: 46px;

        &::after{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            content: '';
            z-index: -1;
            opacity: 0;
            @extend %transition;
        }

        &::before{
            display: inline-block;
            content: '\f055';
            height: 10px;
            width: 10px;
            margin-right: 0;
            border-bottom: 0px;
            border-right: 0px;
            transform: rotate(0);
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
            position: absolute;
            right: 28px;
        }

        &:hover{
            background: #fff;
        }

        &[aria-expanded="true"]{
            color: #fff;
            &::before{
                content: '\f056';
                transform: rotate(0);
            }
            &::after{
                opacity: .7;
                color: #fff;
                background-color: #9C7AF2;
                background-image: linear-gradient(-129deg,#9C7AF2 0,#5E61E7 100%);
            }
        }
    }
    .accordion__panel{
        padding: 23px 30px 29px;
        box-shadow: 0 15px 50px rgba(89,69,230,0.12);
        @extend %transition;
        p{
            font-size: 15px;
            font-weight: 400;
            letter-spacing: 0em;
            line-height: 1.74;
        }
    }
}

.accodion-style--1 .accordion__item + .accordion__item {
    border-top: 0 none;
}

.accodion-style--1{
    .accordion__item {
        margin-bottom: 20px;
        box-shadow: 0 0 40px 10px rgba(89,69,230,0.05);
    }
}








