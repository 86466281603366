/*=========================
    Footer Area
===========================*/

.footer-area{
    .footer-top{
        border-top-width: 1px;
        border-top-color: #ededed;
        border-top-style: solid;
    }
}
.footer-widget {
    .title {
        padding-bottom: 13px;
        border-bottom: 1px solid rgba(238, 238, 238, 0.13);
        font-size: 18px;
        font-weight: 600;
        line-height: 1.34;
        color: #333;
        border-bottom-color: rgba(0, 0, 0, 0);
        margin-bottom: 10px;
    }
    .inner {
        .logo {

        }
        .addres {
            margin-top: 22px;
            p {
                margin-bottom: 10px;
                font-size: 15px;
                font-weight: 400;
                a {
                    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                    &:hover {
                        color: $theme-color;
                    }
                }
            }
        }
        .author {
            a {
                font-size: 15px;
                color: #38cb89;
                &:hover {
                    color: $theme-color;
                }
            }
        }
        ul {
            &.menu{
                padding: 0;
                margin: 0;
                list-style: none;
                li{
                    margin-bottom: 10px;
                    a {
                        font-size: 15px;
                        font-weight: 400;
                        line-height: 1.74; 
                        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                        color: $body-color;
                        &:hover{
                            color: $theme-color;
                        }
                    }
                }
            }
        }
    }

    .tweet{
        .tweet-text{
            position: relative;
            font-size: 14px;
            border: 1px solid #ededed;
            border-radius: 5px;
            padding: 19px 20px 25px;
            margin-bottom: 25px;
            word-wrap: break-word;
            background-color: rgba(89,69,230,0.05);
            

            &::before ,
            &::after{
                content: '';
                display: block;
                position: absolute;
                width: 0;
                height: 0;
                border-style: solid;
                transform: rotate(45deg);
            }

            &::before{
                left: 30px;
                bottom: -12px;
                border-color: transparent #ededed transparent transparent;
                border-width: 12px;
            }

            &::after{
                left: 33px;
                bottom: -10px;
                border-color: transparent #f7fbfe transparent transparent;
                border-width: 10px;
            }

            p{
                margin-bottom: 0;
                a{
                    color: #38cb89;
                    font-weight: 600;
                }
            }
        }
        .tweet-info {
            position: relative;
            padding-left: 55px;
            &::before{
                content: '\f099';
                font-size: 18px;
                line-height: 1;
                position: absolute;
                top: 0;
                left: 23px;
                display: block;
                font-family: 'Font Awesome 5 Brands';
                font-weight: 400;
                color: #5945e6;
            }
            .tweet-inner { 
                h6 {
                    font-size: 14px;
                    color: #333;
                    margin-bottom: 1px;
                    font-weight: 600;
                }
                span {
                    font-size: 14px;
                    color: #ababab;
                }
            }
        }
    }
}


/*======================
    Footer Style Two 
========================*/

.footer-style--2{
    background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,#5E60E7),color-stop(65%,#9C7AF2));
    background: -moz-linear-gradient(-259deg,#5E60E7 0,#9C7AF2 65%);
    background: -webkit-linear-gradient(-259deg,#5E60E7 0,#9C7AF2 65%);
    background: -o-linear-gradient(-259deg,#5E60E7 0,#9C7AF2 65%);
    background: -ms-linear-gradient(-259deg,#5E60E7 0,#9C7AF2 65%);
    background: linear-gradient(-259deg,#5E60E7 0,#9C7AF2 65%);

    .copyright-list-inner{
        .copyright-list{
            li{
                display: inline-block;
                padding: 3px 14px;
                color: #fff;
                font-size: 15px;
                font-weight: 400;
                position: relative;
                &::before{
                    position: absolute;
                    background: #ffffff;
                    width: 1px;
                    height: 16px;
                    content: "";
                    top: 11px;
                    left: 0;
                }
                &:first-child{
                    &::before{
                        display: none;
                    }
                }
            }
        }
    }
}


/*=====================
Footer Style Three 
=======================*/

.footer-bg-images{
    background-image: url(/assets/images/bg/footer-01.png);
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
}
.footer-style--3 {
    position: relative;
    z-index: 2;
    .footer-top {
        .thumbnail {
            margin-bottom: 100px;
            img{
                margin-top: -215px;
                position: relative;
                z-index: 2;
            }
        }
    }
    .vc_row-separator {
        position: absolute;
        width: 100%;
        left: 0;
        z-index: 1;
        line-height: 0;
        &.center_curve {
            svg {
                height: 100px;
                fill: #161953;
                width: 100%;
            }
        }
        &.top {
            top: 0;
        }
    }

    .copyright-inner{
        margin-top: 60px;
        .content{
            @extend %liststyle;
            li{
                display: inline-block;
                padding: 3px 14px;
                color: #fff;
                font-size: 15px;
                font-weight: 400;
            }
        }
    }
}


/*======================
    Footer Style Four 
=======================*/

.footer-style--4{
    .footer-top{
        .contact-btn{
            line-height: 1.23;
            color: #38cb89;
            font-size: 18px;
            margin-top: 35px;
            padding: 0;
            border: 0 none;
            position: relative;
            @media #{$sm-layout} {
                margin-top: 10px;
            }
            @media #{$md-layout} {
                margin-top: 10px;
            }
            &::after{
                content: '';
                width: 0;
                height: 1px;
                bottom: 0;
                position: absolute;
                left: auto;
                right: 0;
                z-index: -1;
                transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
                background: currentColor;
            }
            &:hover{
                color: #5945e6;
                &::after{
                    width: 100%;
                    left: 0;
                    right: auto;
                }
            }
        }
    }
    .footer-widget{
        h3{
            &.title{
                font-size: 16px;
            }
        }
        .inner{
            p{
                font-size: 15px;
                font-weight: 400;
                color: #7e7e7e;
                margin-bottom: 10px;
                a{
                    color: #7e7e7e;
                }
            }
        }
    }
}




/*======================
    Footer Style Five 
=======================*/


.footer-style--5 {
    .newsletter-inner {
        width: 800px;
        max-width: 100%;
        margin: 0 auto;
        input {
            background: transparent;
            border: 0;
            border-radius: 0;
            border-bottom: 3px solid #000;
            text-align: center;
            font-size: 48px;
            height: 67px;
            padding: 14px 0 23px;
            border-color: rgba(89,69,230,0.20);
            color: #5945e6;
            @extend %transition;
            width: 100%;
            @media #{$sm-layout} {
                font-size: 26px;
                height: 50px;
            }
            @media #{$md-layout} {
                font-size: 26px;
                height: 50px;
            }

            &:focus {
                border-color: #5945e6;
            }
        }
        label {
            display: block;
        }
    }

    .copyright-inner {
        p {
            padding: 3px 14px;
            position: relative;
            @media #{$sm-layout} {
                padding: 3px 6px;
            }
            & + p {
                &::before{
                    position: absolute;
                    top: 8px;
                    left: -2px;
                    content: '';
                    height: 16px;
                    width: 1px;
                    background: rgba(0, 0, 0, 0.5);
                    @media #{$sm-layout} {
                        display: none;
                    }
                    @media #{$md-layout} {
                        display: none;
                    }
                }
            }
        }
    }
}

.footer-style--5 .newsletter-inner input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #5945e6;
}
.footer-style--5 .newsletter-inner input::-moz-placeholder { /* Firefox 19+ */
    color: #5945e6;
}
.footer-style--5 .newsletter-inner input:-ms-input-placeholder { /* IE 10+ */
    color: #5945e6;
}






/*==========================
    Copyright Area 
============================*/

.copyright-style--1{
    border-top-width: 1px;
    border-top-color: #ededed;
    border-top-style: solid;
    padding-top: 31px;
    padding-bottom: 31px;
    .copyright{
        p{

        }
    }
}

.backto-top div {
    z-index: 9 !important;
}
