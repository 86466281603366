/*=========================
    Showcase Download 
==========================*/

.appshowcase-download {
    .thumbnail {
        margin-right: 60px;
        text-align: right;
        img {

        }
    }
    .app-download-inner {
        width: 600px;
        max-width: 100%;
        @media #{$md-layout} {
            width: 100%;
            max-width: 100%;
            padding: 0 20px;
        }
        @media #{$sm-layout} {
            width: 100%;
            max-width: 100%;
            padding: 0 20px;
            text-align: center;
        }
        p {
            font-size: 18px;
            line-height: 1.78;
            padding-right: 15%;
            margin-bottom: 40px;
            @media #{$md-layout} {
                padding-right: 0;
            }
            @media #{$sm-layout} {
                padding-right: 0;
            }
        }
    }
}





