/*=====================
    Payment Area 
=======================*/

.payment-sestem-bg-image{
    background-image: url(/assets/images/bg/bg-image-8.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top 80px center;
    position: relative;
    .for-bottom-shape{
        position: relative;
        padding-bottom: 200px;
        
        .vc_row-separator{
            position: absolute;
            width: 100%;
            left: 0;
            z-index: 1;
            line-height: 0;
    
            &.tilt_left{
                &.bottom{
                    bottom: 0;
                }
            }
            svg{
                height: 350px;
                fill: transparent;
                width: 100%;
                @media #{$lg-layout} {
                    height: 252px;
                }
                @media #{$md-layout} {
                    height: 252px;
                }
                @media #{$sm-layout} {
                    height: 250px;
                }
            }
        }
    }

}




.payment-heading{
    position: relative;
    font-size: 34px;
    line-height: 1.42;
    display: inline-block;
    sup{
        position: absolute;
        top: 15px;
        transform: translateY(-50%);
        font-size: 138px;
        font-weight: 700;
        line-height: 1;
        color: #f3eff8;
        z-index: -1;
        left: -55px;
        @media #{$md-layout} {
            font-size: 105px;
            left: 0;
        }
        @media #{$lg-layout} {
            left: 0;
            font-size: 105px;
        }
        @media #{$sm-layout} {
            font-size: 60px;
            left: 0;
        }
    }
    &.sup-right {
        sup {
            right: -25px;
            left: auto;
            @media #{$lg-layout} {
                right: 0;
                font-size: 105px;
            }
            @media #{$md-layout} {
                right: 0;
                font-size: 105px;
            }
            @media #{$sm-layout} {
                right: 0;
                font-size: 60px;
            }
        }
    }
}

.payment-service-inner {
    position: relative;
    z-index: 2;
    .vc_row-separator{
        position: absolute;
        width: 100%;
        left: 0;
        z-index: -1;
        line-height: 0;
        &.tilt_left{
            svg{
                fill: transparent;
                width: 100%;
                height: 520px;
            }
            
        }
        &.bottom{
            bottom: 0;
        }
    }
}

.payment-icon-service-area{
    .online-credit-area{
        background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,#5E60E7),color-stop(100%,#9C7AF2));
        background: -moz-linear-gradient(-259deg,#5E60E7 0,#9C7AF2 100%);
        background: -webkit-linear-gradient(-259deg,#5E60E7 0,#9C7AF2 100%);
        background: -o-linear-gradient(-259deg,#5E60E7 0,#9C7AF2 100%);
        background: -ms-linear-gradient(-259deg,#5E60E7 0,#9C7AF2 100%);
        background: linear-gradient(-259deg,#5E60E7 0,#9C7AF2 100%);
        position: relative;
        z-index: 2;
        padding-top: 60px;
        .vc_row-separator {
            position: absolute;
            width: 100%;
            left: 0;
            z-index: -1;
            line-height: 0;
            svg{
                height: 520px;
                fill: transparent;
                width: 100%;
            }
            &.top {
                border: 0;
                transform: scale(-1, -1);
            }
        }
    }
    .section-separator{
        .separator--1{
            height: 48px;
        }
    }
}

/*======================
    Effortless Area 
========================*/
.effortless-area {
    position: relative;
    z-index: 2;
    .thumbnail{
        text-align: left;
    }
    .effortless-content {
        .inner {
            .space--1 {
                height: 200px;
                @media #{$md-layout} {
                    height: 30px;
                }
                @media #{$sm-layout} {
                    height: 30px;
                }
            }
            h2 {
                max-width: 370px;
                position: relative;
                font-size: 34px;
                line-height: 1.42;
                display: inline-block;
                margin-bottom: 32px;
                sup{
                    right: -25px;
                    position: absolute;
                    top: 15px;
                    transform: translateY(-50%);
                    font-size: 138px;
                    font-weight: 700;
                    line-height: 1;
                    color: #f3eff8;
                    z-index: -1;
                    @media #{$md-layout} {
                        right: 0;
                        font-size: 60px;
                    }
                    @media #{$sm-layout} {
                        right: 0;
                        font-size: 60px;
                    }
                }
            }
            p {
                font-size: 18px;
                line-height: 1.78;
                margin-bottom: 42px;
            }
        }
    }


}


/*========================
    Pricing Table Area 
=========================*/

.pricing-bg-svg {
    position: relative;
    .vc_row-separator {
        transform: scale(-1, -1);
        svg{
            height: 350px;
            fill: transparent;
            width: 100%;
        }
        &.tilt_right {
            position: absolute;
            width: 100%;
            left: 0;
            z-index: 1;
            line-height: 0;
            &.top {
                top: 0;
            }
        }
    }
}

.payment-pricig-table{
    .pricing-bg-shape{
        background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,#8481EC),color-stop(100%,#A990F2));
        background: -moz-linear-gradient(-259deg,#8481EC 0,#A990F2 100%);
        background: -webkit-linear-gradient(-259deg,#8481EC 0,#A990F2 100%);
        background: -o-linear-gradient(-259deg,#8481EC 0,#A990F2 100%);
        background: -ms-linear-gradient(-259deg,#8481EC 0,#A990F2 100%);
        background: linear-gradient(-259deg,#8481EC 0,#A990F2 100%);
    }
}













