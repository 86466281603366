/*======================
    App Landing 
=======================*/
.app-feature{
    .icon{
        width: 120px;
        height: 120px;
        line-height: 120px;
        text-align: center;
        border-radius: 40px;
        font-size: 34px;
        color: #fff;
        background-image: linear-gradient(-221deg, #e17643 0%, #f0a86b 100%);
        box-shadow: 0 15px 50px rgba(89, 69, 230, 0.12);
        margin-bottom: 52px;
        @media #{$sm-layout} {
            width: 80px;
            height: 80px;
            line-height: 80px;
            margin-bottom: 20px;
        }
        @media #{$md-layout} {
            width: 80px;
            height: 80px;
            line-height: 80px;
            margin-bottom: 20px;
        }
    }
    .content{
        h4 {
            &.title {
                font-size: 24px;
                margin-bottom: 14px;
                color: #fff;
            }
        }
        p {
            color: rgba(255,255,255,0.7);
        }
    }
}




/*=========================
All Feature Area 
===========================*/
.all-feature-area{
    .thumbnail{
        text-align: right;
    }
    .content{
        padding-left: 100px;
        width: 700px;
        max-width: 100%;
        @media #{$lg-layout} {
            padding-left: 30px;
        }
        @media #{$md-layout} {
            padding-left: 30px;
        }
        @media #{$sm-layout} {
            padding-left: 30px;
        }
        h2{
            margin-bottom: 19px;
        }
        p{
            font-size: 18px;
            line-height: 1.78;
            color: rgba(255,255,255,0.7);
            margin-bottom: 39px;
        }
        .ar-button{
            margin-bottom: 49px;
        }
    }
}


/*=====================
    Screenshot Area 
=======================*/
.screenshot-top {
    @media #{$lg-layout} {
        padding-left: 100px;
        padding-right: 100px;
    }
    @media #{$md-layout} {
        padding-left: 50px;
        padding-right: 50px;
    }
    @media #{$sm-layout} {
        padding-left: 30px;
        padding-right: 30px;
    }
    .app-screens-inner {
        text-align: center;
        @media #{$md-layout} {
            text-align: left;
            margin-bottom: 20px;
        }
        @media #{$sm-layout} {
            text-align: left;
            margin-bottom: 20px;
        }
        h2 {
            margin-bottom: 0;
            line-height: 1;
        }
        .screen {
            font-size: 15px;
            line-height: 2;
            letter-spacing: 1em;
            font-weight: 500;
            text-transform: uppercase;
            color: rgba(255,255,255,0.7);
        }
    }
    .applist{
        .list-style--1{
            li{
                color: #ffffff;
            }
        }
    }
}


.screenshot-slide-wrapper{
    margin: 0 -15px;
    .screenshot{
        padding: 0 15px;
    }
}




