/*=====================
    Breadcrumb Area 
======================*/

.breadcrumb-default{
    border-bottom-width: 0px;
    border-bottom-color: rgba(0, 0, 0, 0);
    padding-top: 196px;
    padding-bottom: 114px;
    background-color: #fff;
    background-image: linear-gradient(-180deg,#fff 0,#eceefa 100%);
    @media #{$sm-layout} {
        padding-top: 134px;
        padding-bottom: 96px;
    }
    .content{
        .inner{
            text-align: center;
            h1 {
               &.page-title{
                    font-size: 48px;
                    font-weight: 500;
                    line-height: 1.17;
                    @media #{$sm-layout} {
                        font-size: 30px;
                    }
               }
            }
            .breadcrumb-list {
                @extend %liststyle;
                .breadcrumb-item {
                    display: inline-block;
                    a {
                        color: rgba(109, 112, 166, 0.5);
                        font-size: 14px;
                        font-weight: 400;
                        letter-spacing: 2px;
                        line-height: 1.58;
                        text-transform: uppercase;
                    }
                    &.active {
                        color: $theme-color;
                    }
                }
            } 
        }
    }
}

