/*=================
List Style 
==================*/

.list-style--1{
    @extend %liststyle;

    li{
        color: #696969;
        font-weight: 400;
        font-size: 14px;
        line-height: inherit;
        margin-bottom: 10px;
        i{
            color: #38cb89;
            padding-right: 10px;
        }
    }
    &.text-white{
        li{
            color: #ffffff;
        }
    }
}

.list-style--2 {
    li {
        font-size: 24px;
        color: #333;
        line-height: 1.2;
        font-weight: 400;
        & + li {
            margin-top: 24px;
        }
    }
    &.text-white {
        li{
            color: #ffffff;
        }
    }
}










