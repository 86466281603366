/*=====================
    Software Area 
=======================*/

.software-service-wrapper {
    .thumbnail {
        text-align: right;
    }
    .service-wrapper {
        width: 600px;
        max-width: 100%;
        @media #{$lg-layout} {
            width: 100%;
            max-width: 100%;
            padding: 0 20px;
        }
        @media #{$md-layout} {
            width: 100%;
            max-width: 100%;
            padding: 0 20px;
        }
        @media #{$sm-layout} {
            width: 100%;
            max-width: 100%;
            padding: 0 20px;
        }
    }
}
.software-pricing-table {
    .thumbnail{
        text-align: right;
    }
    .software-pricing-inner {
        width: 600px;
        max-width: 100%;
        @media #{$md-layout} {
            max-width: 100%;
            padding: 0 30px;
        }
        @media #{$lg-layout} {
            max-width: 100%;
            padding: 0 30px;
        }
        @media #{$sm-layout} {
            max-width: 100%;
            padding: 0 30px;
        }
    }
}

// Software Video Style 
.software-service-area {
    .video-popup {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 100% !important;
        svg {
            color: #38cb89 !important;
        }
    }
}