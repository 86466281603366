/*======================
    Digital Agency 
=======================*/

.working-process-area{
    .work-proces-bg{
        background-image: url(/assets/images/bg/work.png);
        background-repeat: no-repeat;
        background-position: 42% center;
    }
    .image-wrapper{
        text-align: right;
        @media #{$md-layout} {
            text-align: left;
        }
        @media #{$sm-layout} {
            text-align: left;
        }
    }
    .thumbnail-inner {
        display: inline-block;
        @media #{$md-layout} {
            display: block;
        }
        @media #{$sm-layout} {
            display: block;
        }
        .thumbnail {
            @media #{$md-layout} {
                margin-bottom: 20px;
            }
            @media #{$sm-layout} {
                margin-bottom: 20px;
            }
            @media #{$lg-layout} {
                display: block;
                text-align: left;
            }
            @media #{$md-layout} {
                display: block;
                text-align: left;
            }
            @media #{$sm-layout} {
                display: block;
                text-align: left;
            }
            img {
                border-radius: 5px;
                overflow: hidden;
                display: inline-block;
                @media #{$md-layout} {
                    width: 100%;
                }
                @media #{$sm-layout} {
                    width: 100%;
                }
            }
        }
    }

    .work-process-inner{
        padding-left: 115px;
        @media #{$lg-layout} {
            padding-left: 30px;
        }
        @media #{$md-layout} {
            padding-left: 30px;
        }
        @media #{$sm-layout} {
            padding-left: 30px;
        }
    }
}




.cooperation-bg-images {
    background-image: url(/assets/images/bg/digital-bg-1.png);
    background-repeat: no-repeat;
    background-position: left -200px bottom 150px;
}



/*=======================
    Gallery Area 
========================*/
.bg-gallery-area{
    background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,#F6F5F9),color-stop(71%,#FFF));
}

.dg-gallery-list{
    grid-template-columns: repeat( 3,1fr);
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    display: grid;
    @media #{$large-mobile} {
        grid-template-columns: repeat(1, 1fr);
        grid-column-gap: 0;
        grid-row-gap: 20px;
    }
    @media #{$sm-layout} {
        grid-template-columns: repeat(1, 1fr);
        grid-column-gap: 0;
        grid-row-gap: 20px;
    }
    .item-grid {
        position: relative;
        a{
            display: block;
            height: 100%;
            width: 100%;
            .thumbnail{
                height: 100%;
                img{
                    min-height: 100%;
                    border-radius: 5px;
                    overflow: hidden;
                    @media #{$sm-layout} {
                        width: 100%;
                    }
                }
            }
            .hover-action{
                position: absolute;
                right: 30px;
                left: 30px;
                bottom: 21px;
                h3{

                    &.title{
                        font-size: 18px;
                        line-height: 1.78;
                        color: #fff;
                        margin: 0;
                        @extend %transition;
                    }

                    &.read-more-button{
                        font-size: 18px;
                        line-height: 1.78;
                        color: #fff;
                        margin: 0;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        opacity: 0;
                        visibility: hidden;
                        transform: translateY(20px);
                        @extend %transition;
                        i,
                        span {
                            margin-left: 5px;
                        }
                    }
                }
            }
        }
        &:hover {
            a {
                .hover-action {
                    h3{
                        &.title{
                            opacity: 0;
                            visibility: hidden;
                            transform: translateY(-20px);
                        }
                        &.read-more-button{
                            opacity: 1;
                            visibility: visible;
                            transform: none;
                        }
                    }
                }
            }
        }
        &.grid-width-2 {
            grid-column: span 2;
            @media #{$sm-layout} {
                grid-column: span 1;
            }
        }
    }
}



// Start Digital Agency Contact
.digital-agency-contant{
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    .thumbnail{
        position: relative;
        img{
            transition: transform 1s;
            width: 100%;
        }
        &::after{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: .3;
            background-image: linear-gradient(-180deg, transparent 39%, #1c0f69 100%);
        } 
    }
    .heading{
        position: absolute;
        bottom: 23px;
        left: 30px;
        right: 30px;
        margin: 0 auto;
        h4{
            &.title{
                font-size: 18px;
                line-height: 1.78;
                color: #fff;
                margin: 0 auto;
            }
        }
    }
    &:hover {
        .thumbnail {
            img {
                transform: scale(1.1, 1.1);
            }
        }
    }
}






