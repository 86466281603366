/*===========================
Header Area 
============================*/
.header{
    &.header--transparent{
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9999;
        width: 100%;
    }
}
.header-wrapper{
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 150px;
    padding-right: 150px;

    @media #{$laptop-device} {
        padding-left: 50px;
        padding-right: 50px;
    }
    @media #{$lg-layout} {
        padding-left: 50px;
        padding-right: 50px;
    }
    @media #{$md-layout} {
        padding-left: 50px;
        padding-right: 50px;
        padding-top: 30px;
    }

    @media #{$sm-layout} {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 30px;
    }

    .branding{
        flex-shrink: 0;
        flex-basis: 20%;
      
        @media #{$md-layout} {
            flex-basis: 40%;
        }
        @media #{$sm-layout} {
            flex-basis: 50%;
        }
        .logo {
            a {
                img {
                    padding-top: 15px;
                    padding-bottom: 15px;
                    padding-left: 0px;
                    @media #{$sm-layout} {
                        width: 130px;
                    }
                }
            }
        }
    }
    .page-navigation {
        flex-grow: 1;
    }
    .header-right {
        flex-shrink: 0;
        flex-basis: 20%;
        display: flex;
        margin: 0 -12px;
        align-items: center;
        justify-content: flex-end;
        @media #{$md-layout} {
            flex-basis: 60%;
        }
        @media #{$sm-layout} {
            flex-basis: 50%;
        }
    }
    button{
        &.ar-button {
            @media #{$lg-layout} {
                padding: 0 28px;
            }
        }
    }
}



/*=====================
    Header Right 
=======================*/

.header-right{
    .header-links{
        padding: 0 12px;
        @media #{$laptop-device} {
            display: none !important;
        }
        .links{
            font-weight: 600;
            position: relative;
            overflow: hidden;
            z-index: 1;
            color: #38cb89;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            &::after{
                content: '';
                width: 0;
                height: 1px;
                bottom: 0;
                position: absolute;
                left: auto;
                right: 0;
                z-index: -1;
                transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
                background: currentColor;
                transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            }
            &:hover{
                color: #38cb89;
                &::after{
                    width: 100%;
                    left: 0;
                    right: auto;
                }
            }
        }
    }
    .header-button{
        padding: 0 12px;
    }
}


/* Mobile Menu */

.popup-mobile-menu-wrapper{
    .popup-menu {
        visibility: visible;
        opacity: 1;
        .inner{
            -webkit-transform: none;
            -ms-transform: none;
            transform: none;
        }
    }
}

.popup-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999999;
    background: rgba(0, 0, 0, 0.9);
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    z-index: 99999;
    .inner{
        background: #000000;
        position: relative;
        width: 400px;
        max-width: 80%;
        height: 100%;
        text-align: left;
        -webkit-transition: all .3s;
        transition: all .3s;
        overflow-y: auto;
        padding-top: 85px;
        -webkit-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
        transform: translateX(-100%);
        cursor: default;
    }
}


/*---------------------
    Main Menu  
----------------------*/


.mainmenu {
    li {
        &.has-dropdown {
            ul {
                &.submenu {
                    padding: 18px 0 22px;
                    min-width: 240px !important;
                    border: 0;
                    border-radius: 5px;
                    position: absolute;
                    box-shadow: 0 2px 29px rgba(0, 0, 0, 0.05);
                    background: #ffffff;
                    border: none;
                    border-bottom: 3px solid #5945e6;
                    margin-top: -2px;
                    text-align: left;
                    opacity: 0;
                    visibility: hidden;
                    transition: 0.5s;
                    z-index: -9;
                    overflow: hidden;
                    overflow-y: auto;
                    max-height: 522px;
                    li {
                        display: block;
                        a {
                            padding: 9px 30px;
                            color: #ababab;
                            font-size: 15px;
                            font-weight: 400;
                            line-height: 1.47;
                            display: block;
                            text-transform: capitalize;
                            transition: 0.3s;
                        }
                        &:hover {
                            a{
                                color: $theme-color;
                            }
                        }
                    }
                }
            }
            &:hover {
                ul {
                    &.submenu {
                        opacity: 1;
                        visibility: visible;
                        z-index: 999;
                        li {
                            a{
    
                            }
                        }
                    }
                }
            }
        }
    }
}


.page-template-boxed-with-corner { 
    .header-wrapper{
        padding-left: 110px;
        padding-right: 110px;
        @media #{$laptop-device} {
            padding-left: 30px;
            padding-right: 30px;
        }
        @media #{$lg-layout} {
            padding-left: 30px;
            padding-right: 30px;
        }
        @media #{$md-layout} {
            padding-left: 30px;
            padding-right: 30px;
        }
        @media #{$sm-layout} {
            padding-left: 30px;
            padding-right: 30px;
        }
    }
}




/*------------------------------------------
    MObile Menu Responsive 
-------------------------------------------*/
.header-wrapper{
    .page-navigation {
        @media #{$md-layout} {
            position: absolute;
            .mainmenu-nav {
                width: 60vw;
                height: 100vh;
                position: fixed;
                top: 0;
                right: -61vw;
                z-index: 99;
                padding: 55px;
                background: #fff;
                -webkit-transition: all .8s cubic-bezier(.77,.2,.05,1);
                transition: all .8s cubic-bezier(.77,.2,.05,1);
                display: block;
                overflow-y: auto;
                ul{
                    &.mainmenu{
                        text-align: left;
                        li {
                            display: block;
                            > a {
                                padding-top: 12px;
                                padding-bottom: 12px;
                                padding-left: 0;
                                padding-right: 0;
                                display: block;
                                span {
                                    &::after {
                                        display: none;
                                    }
                                }
                            }
                            &.has-dropdown {
                                > a{
                                    position: relative;
                                    &::after {
                                        width: 0;
                                        height: 0;
                                        border-color: #1f1f25 transparent transparent;
                                        border-style: solid;
                                        border-width: 7px 7px 0;
                                        position: absolute;
                                        content: "";
                                        right: 0;
                                        top: 50%;
                                        -webkit-transform: translateY(-50%);
                                        transform: translateY(-50%);
                                        -webkit-transition: all .3s cubic-bezier(.645,.045,.355,1);
                                        transition: all .3s cubic-bezier(.645,.045,.355,1);
                                    }
                                    &.open{
                                        &::after{
                                            width: 0;
                                            height: 0;
                                            border-color: transparent transparent $theme-color;
                                            border-style: solid;
                                            border-width: 0 7px 7px;
                                        }
                                    }
                                }
                                ul{
                                    &.submenu {
                                        position: relative;
                                        height: 0;
                                        -webkit-transition: .3s;
                                        transition: .3s;
                                        padding: 0;
                                        opacity: 0;
                                        visibility: hidden;
                                        background: transparent;
                                        box-shadow: inherit; 
                                        border-bottom: 0 none;
                                        left: 14px;
                                        li {
                                            a {
                                                padding-top: 8px;
                                                padding-bottom: 8px;
                                            }
                                        }
                                        &.active {
                                            height: 100%;
                                            padding: 12px 0;
                                            opacity: 1;
                                            visibility: visible;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        @media #{$sm-layout} {
            position: absolute;
            .mainmenu-nav {
                width: 60vw;
                height: 100vh;
                position: fixed;
                top: 0;
                right: -61vw;
                z-index: 99;
                padding: 55px;
                background: #fff;
                -webkit-transition: all .8s cubic-bezier(.77,.2,.05,1);
                transition: all .8s cubic-bezier(.77,.2,.05,1);
                display: block;
                overflow-y: auto;
                @media #{$sm-layout} {
                    width: 92vw;
                    right: -92vw;
                }
                ul{
                    &.mainmenu{
                        text-align: left;
                        li {
                            display: block;
                            > a {
                                padding-top: 12px;
                                padding-bottom: 12px;
                                padding-left: 0;
                                padding-right: 0;
                                display: block;
                                span {
                                    &::after {
                                        display: none;
                                    }
                                }
                            }
                            &.has-dropdown {
                                > a{
                                    position: relative;
                                    &::after {
                                        width: 0;
                                        height: 0;
                                        border-color: #1f1f25 transparent transparent;
                                        border-style: solid;
                                        border-width: 7px 7px 0;
                                        position: absolute;
                                        content: "";
                                        right: 0;
                                        top: 50%;
                                        -webkit-transform: translateY(-50%);
                                        transform: translateY(-50%);
                                        -webkit-transition: all .3s cubic-bezier(.645,.045,.355,1);
                                        transition: all .3s cubic-bezier(.645,.045,.355,1);
                                    }
                                    &.open{
                                        &::after{
                                            width: 0;
                                            height: 0;
                                            border-color: transparent transparent $theme-color;
                                            border-style: solid;
                                            border-width: 0 7px 7px;
                                        }
                                    }
                                }
                                ul{
                                    &.submenu {
                                        position: relative;
                                        height: 0;
                                        -webkit-transition: .3s;
                                        transition: .3s;
                                        padding: 0;
                                        opacity: 0;
                                        visibility: hidden;
                                        background: transparent;
                                        box-shadow: inherit; 
                                        border-bottom: 0 none;
                                        left: 14px;
                                        li {
                                            a {
                                                padding-top: 8px;
                                                padding-bottom: 8px;
                                            }
                                        }
                                        &.active {
                                            height: 100%;
                                            padding: 12px 0;
                                            opacity: 1;
                                            visibility: visible;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .humberger-menu {
        span {
            font-size: 28px;
            color: #000;
            cursor: pointer;
        }
    }
    @media #{$md-layout}{
        .close-menu {
            position: fixed;
            top: 12px;
            opacity: 0;
            visibility: hidden;
            right: 29px;
            z-index: -10;
            span {
                color: #000;
                font-size: 30px;
                cursor: pointer;
            }
        }
    }
    @media #{$sm-layout}{
        .close-menu {
            position: fixed;
            top: 12px;
            opacity: 0;
            visibility: hidden;
            right: 29px;
            z-index: -10;
            span {
                color: #000;
                font-size: 30px;
                cursor: pointer;
            }
        }
    }
    @media #{$sm-layout}{
        .close-menu {
            position: fixed;
            top: 12px;
            opacity: 0;
            visibility: hidden;
            right: 29px;
            z-index: -10;
            span {
                color: #000;
                font-size: 30px;
                cursor: pointer;
            }
        }
    }
    &.menu-open{
        .page-navigation {
            .mainmenu-nav {
                right: 0;
            }
        }
        @media #{$md-layout}{
            .close-menu {
                opacity: 1;
                visibility: visible;
                z-index: 9999;
            }
        }
        @media #{$sm-layout}{
            .close-menu {
                opacity: 1;
                visibility: visible;
                z-index: 9999;
            }
        }
    }
}


.color--light .header-wrapper .humberger-menu span {
    color: #ffffff; 
}

@media #{$md-layout}{
    .color--light .mainmenu-nav .mainmenu li a {
        color: #1f1f25; 
    }
    .mainmenu-nav .mainmenu > li:hover > a{
        color: $theme-color !important;
    }
}

@media #{$sm-layout}{
    .color--light .mainmenu-nav .mainmenu li a{
        color: #1f1f25; 
    }
    .mainmenu-nav .mainmenu > li:hover > a{
        color: $theme-color !important;
    }
}















