/*===============================
    Testimonial Area 
=================================*/
.testimonial-active-wrapper{
    margin: 0 -15px;
}
.testimonial_style--1{
    padding: 15px;
    text-align: center;
    .inner{
        background: #fff;
        padding: 51px 34px 40px;
        border-radius: 5px;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        @media #{$sm-layout} {
            padding: 20px 15px;
        }
    }
    .thumbnail{
        margin-bottom: 22px;
        img{
            border-radius: 50%;
            display: inline-block;
        }
    }
    .details{
        color: #6d70a6;
        font-size: 18px;
        line-height: 1.78;
        margin-bottom: 25px;
    }
    .title{
        font-size: 18px;
        line-height: 1.23;
        color: #333;
        margin-bottom: 13px;
    }
    .subtitle{
        font-size: 14px;
        font-style: italic;
        text-transform: uppercase;
        letter-spacing: 1px;
        &::before{
            content: ' / ';
        }
    }
    &:hover{
        .inner{
            transform: translateY(-5px);
            box-shadow: 0 0 30px rgba(0, 0, 0, 0.07);
        }
    }
}



/*=======================
All Landing Testimonial 
===========================*/

.testimonial-activation-wrapper .slick-slide img {
    display: inline-block;
}

.testimonial-style--2 {
    display: flex !important;
    width: 100%;
    max-width: 830px;
    margin: 0 auto;
    @media #{$large-mobile} {
        display: block !important;
        padding: 0 30px;
    }
    .thumbnail {
        flex-shrink: 0;
        margin-right: 90px;
        @media #{$lg-layout} {
            margin-right: 20px;
        }
        @media #{$md-layout} {
            margin-right: 20px;
        }
        @media #{$sm-layout} {
            margin-right: 20px;
        }
        img {
            border-radius: 50%;
        }
    }
    .content{
        flex-grow: 1;
        p {
            font-size: 24px;
            line-height: 1.67;
            color: #fff;
        }
        h6 {
            font-size: 24px;
            line-height: 1.5;
            color: #fff;
            margin-bottom: 9px;
            padding-top: 45px;
            @media #{$sm-layout} {
                padding-top: 8px;
            }
        }
        span {
            color: rgba(255, 255, 255, 0.7);
            font-size: 14px;
            &::before{
                content: ' / ';
            }
        }
    }
}











