/*======================
    Countdown Area 
========================*/

.time-counterwrapper{
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(4, 1fr);
    width: 770px;
    max-width: 100%;
    margin: 0 auto;
    @media #{$sm-layout} {
        grid-template-columns: repeat(2, 1fr);
    }
    .time-inner{
        display: inline-block;
        font-size: 80px;
        font-weight: 400;
        line-height: 1;
        color: #f76b1c;
        @media #{$sm-layout} {
            font-size: 40px;
        }
        .text{
            display: block;
            text-transform: uppercase;
            letter-spacing: 2px;
            font-weight: 600;
            font-size: 14px;
            margin-top: 10px;
            color: #333;
        }
    }
}
