/*============================
    Picture Branding Area 
=============================*/

.branding-service {
    .slide-content {
        .slide-info {
            margin-top: 50px;
            margin-bottom: 50px;
            max-width: 570px;
            background: #fff;
            border-radius: 8px;
            box-shadow: 0 15px 50px rgba(89, 69, 230, 0.12);
            padding: 54px 50px 52px;
            transition-delay: .5s;
            @extend %transition;
            transition-delay: .5s;
            opacity: 0;
            transform: translateX(100%);
            @extend %transition;
           
            @media #{$md-layout} {
                margin: 0 auto;
                margin-bottom: 50px;
            }
            @media #{$sm-layout} {
                padding: 30px 30px 30px;
                margin: 0 auto;
                margin-bottom: 50px;
            }
            h5 {
                font-size: 34px;
                line-height: 1.65;
                font-weight: 400;
                color: #333;
                margin-bottom: 11px;
                padding-right: 13px;
            }
            p {
                font-size: 18px;
                line-height: 1.78;
            }
        }
    }
    .thumbnail {
        margin-right: 20px;
        text-align: right;
        font-size: 0;
        overflow: hidden;
        @media #{$md-layout} {
            margin-right: 0;
        }
        @media #{$sm-layout} {
            margin-right: 0;
        }
      
        img {
            transition-delay: .5s;
            opacity: 0;
            transform: translateX(-100%);
            @extend %transition;
            display: inline-block;
        }
    }
}

.slick-active {
    &.slick-current {
        .slide-content {
            .slide-info{
                opacity: 1;
                transform: translateY(0);
            }
        }
        .thumbnail {
            img {
                opacity: 1;
                transform: translateY(0);
            }
        }
    }
}










