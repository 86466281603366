/*================================
    Marketing Initiatives Area
==================================*/

// Sass Landing

.marketing-inner{
    width: 600px;
    max-width: 100%;
    float: right;
    @media #{$md-layout} {
        width: 100%;
        margin-bottom: 30px;
        padding: 0 15px;
    }
    @media #{$sm-layout} {
        width: 100%;
        margin-bottom: 30px;
        padding: 0 15px;
    }
}
.marketing-initiatives-area {
    position: relative;
    .marketing-wrapper {
        .accodion-style--1{
            
        }
    }
}
















