/*===================
Ebook Area 
======================*/

.page-template-boxed-with-corner{
    background: url(/assets/images/aeroland/body-special-bg-shape-01.png) right top no-repeat, url(/assets/images/aeroland/body-special-bg-shape-02.png) left top 570px no-repeat, url(/assets/images/aeroland/body-special-bg-shape-03.png) left bottom no-repeat, url(/assets/images/aeroland/body-special-bg-shape-04.png) right bottom no-repeat, #e4e8f6;
    overflow: hidden;
}
.page-template-boxed-with-corner-background {
    max-width: 1820px;
    margin: 50px auto;
    box-shadow: none;
    .site{
        background: #fff;
        overflow: hidden;
    }
}


.chanters-bg {
    background-image: url(/assets/images/bg/ebook-image-02.png);
    background-repeat: no-repeat;
    background-position: top left 45%;
}


.echanters-inner {
    .thumbnail{
        margin-bottom: -105px !important;
        img{
            border: 5px solid #e8e8e8;
            border-radius: 28px;
            -moz-box-shadow: 40px 40px 100px rgba(0,0,0,0.1);
            -webkit-box-shadow: 40px 40px 100px rgba(0,0,0,0.1);
            box-shadow: 40px 40px 100px rgba(0,0,0,0.1);
        }
    }
}

/*======================
    Testimonial Area 
========================*/
.e-testimonial-area{
    .space-top{
        height: 227px;
        @media #{$md-layout} {
            height: 146px;
        }
        @media #{$sm-layout} {
            height: 146px;
        }
    }
}


