/*========================
    Service Area 
==========================*/

.service {
    padding: 35px 40px 49px;
    box-shadow: 0 4px 20px rgba(51, 51, 51, 0.05);
    background: #fff;
    border-radius: 5px;
    position: relative;
    z-index: 2;
    transition: 0.5s;
    &::before{
        z-index: -1;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #fff;
        border-radius: 5px;
        opacity: 0;
        visibility: hidden;
    }
    .icon {
        position: relative;
        background: #f6f5f9;
        border-radius: 50%;
        height: 60px;
        width: 60px;
        margin-bottom: 16px;
        transition: 0.5s;
        img {
            max-width: 40px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    .content {
        h5 {
            &.heading {
                color: #6d70a6;
                font-size: 18px;
                margin-bottom: 16px;
                transition: 0.5s;
            }
        }
        p {
            transition: 0.5s;
        }
    }
    &:hover {
        color: #fff;
        transform: translateY(-5px);
        .icon {
            background: rgba(246, 245, 249, 0.2);
        }
        .content {
            h5 {
                &.heading {
                    color: #ffffff;
                }
            }
            p {
                color: #ffffff;
            }
        }
        
        &::before{
            opacity: .7;
            visibility: visible;
            background-color: #9C7AF2;
            background-image: linear-gradient(-138deg,#9C7AF2 0,#5E61E7 100%);
        }
    }
}


/*=======================
Service Style Two 
=========================*/
.service_style--2 {
    text-align: center;
    .icon{
        margin-bottom: 20px;
        @extend %transition;
        img{

        }
    }
    .content{
        h5{
            &.heading{
                line-height: 1.67;
                font-size: 18px;
                font-weight: 500;
                color: #333;
                margin-bottom: 0;
                @extend %transition;
            }
        }
    }
    &:hover{
        .icon{
            transform: translateY(-5px);
            img{
    
            }
        }
        .content{
            h5{
                &.heading{
                    color: $theme-color;
                }
            }
        }
    }
    &::after{
        position: absolute;
        content: '';
        right: -15px;
        top: 50%;
        transform: translateY(-50%);
        height: 100px;
        background: #d1d9df;
        width: 1px;
        @media #{$sm-layout} {
            display: none;
        }
        @media #{$md-layout} {
            display: none;
        }
        @media #{$lg-layout} {
            display: none;
        }
    }
}

.single-service{
    &:last-child{
        .service_style--2{
            &::after{
                display: none;
            }
        }
    }
}



/*=======================
Service Style Three 
=========================*/


.service-style--4{
    text-align: center;
    @extend %transition;

    .thumbnail{
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        img{
            transition: transform 1s;
            width: 100%;
        }
        &::after{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: .5;
            background-image: linear-gradient(-180deg, transparent 39%, #1c0f69 100%);
        }
        
        .action-title{
            position: absolute;
            bottom: 30px;
            left: 0;
            right: 0;
            max-width: 250px;
            margin: 0 auto;
            z-index: 2;
            h4{
                font-size: 24px;
                line-height: 1.5;
                color: #fff;
            }
        }
    }
    .content{
        margin-top: 25px;
        p{

        }
    }
    &:hover{
        transform: translateY(-5px);
        .thumbnail{
            img{
                transform: scale(1.1, 1.1);
            }
        }
    }
}
