/*=======================
    Attribute Area 
=========================*/



.attribute-list{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 54px 30px;
    width: 100%;
    @extend %liststyle;
    li{
        .title{
            color: #333;
            text-transform: uppercase;
            letter-spacing: 1px;
            margin-bottom: 0;
        }
        .value{
            margin-top: 14px;
            display: block;
        }
    }
}



/*==================================
    Attribute List Style Two 
====================================*/
.attribute-list--2{
    width: 100%;
    border-collapse: collapse;
    @extend %liststyle;
    padding: 40px 30px;
    background: #fff;
    border: 1px solid #eee;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    li{
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        div{
            padding: 12px 0;
        }
        .name{
            .title{
                color: #333;
                text-transform: uppercase;
                letter-spacing: 1px;
                margin-bottom: 0;
            }
        }
        .value{
            text-align: right;
        }
        & + li{
            border-top: 1px solid #eee;
        }
    }
}






