/*===================
Section Title 
======================*/
.section-title {
    margin-top: -10px;
    h3 {
        &.heading {
            font-size: 48px;
            line-height: 1.34;
            font-weight: 400;
            margin-bottom: 4px;
            @media #{$lg-layout} {
                font-size: 40px;
            }
            @media #{$md-layout} {
                font-size: 34px;
            }
            @media #{$sm-layout} {
                font-size: 26px;
            }
        }
    }

    p{
        font-size: 15px;
        font-weight: 400;
        letter-spacing: 0em;
        line-height: 1.74;
        color: #7e7e7e;
    }
    h2 {
        @media #{$lg-layout} {
            font-size: 40px;
        }
        @media #{$md-layout} {
            font-size: 34px;
        }
        @media #{$sm-layout} {
            font-size: 25px;
        }
    }
}


.section-title--3 {
    span{
        color: #ee7455;
        font-size: 15px;
        font-weight: 600;
        display: block;
        margin-bottom: 25px;
        @media #{$sm-layout} {
            margin-bottom: 9px;
        }
    }
    .title{
        font-size: 40px;
        max-width: 770px;
        line-height: 1.4;
        font-weight: 400;
        color: #333;
        margin: 0 auto;
        @media #{$sm-layout} {
            font-size: 25px;
        }
    }
}




.section-title--4{
    span {
        color: #38cb89;
        font-size: 15px;
        font-weight: 600;
        letter-spacing: 3px;
        text-transform: uppercase;
        line-height: 1.34;
        display: block;
        margin-bottom: 24px;
    }
    h3{
        &.title {
            font-size: 34px;
            line-height: 1.42;
            @media #{$sm-layout} {
                font-size: 26px;
                line-height: 1.42;
                margin-bottom: 10px !important;
            }
        }
    }
}




.section-title--5{
    span {
        line-height: 1.74;
        color: #ee7455;
        font-size: 15px;
        margin-bottom: 20px;
        display: block;
        font-weight: 600;
    }
    h3{
        &.title {
            font-size: 34px;
            line-height: 1.42;
        }
    }
}
