// All Style Import Here
@import '~bootstrap/scss/bootstrap.scss';
@import "assets/css/plugins.css";
@import "assets/scss/style";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import '~react-modal-video/scss/modal-video.scss';
@import '~lightgallery.js/dist/css/lightgallery.css';
@import "~react-tabs/style/react-tabs.css";
@import "~animate.css/animate.min.css";


