/*=========================
    Brand Area 
===========================*/

.brand {
    position: relative;
    text-align: center;
    display: inline-block;
    img {
        display: inline-block;
    }
    .thumb2 {
        transition: 0.4s;
        text-align: center;
        display: inline-block;
    }
    .thumb {
        img {
            transition: 0.4s;
        }
    }

    .thumb{
        transition: all .3s linear;
        position: absolute;
        top: 50%;
        left: 50%;
        opacity: 0;
        visibility: hidden;
        transform: translateY(-50%) translateX(-50%);
        transition: 0.4s;
        img{
            transition: 0.4s;
        }
    }
    &:hover{
        .thumb2 {
            visibility: hidden;
            opacity: 0;
            img{

            }
        }
        .thumb {
            opacity: 1;
            visibility: visible;
        }
    }
}


.brand-activation{
    .slick-track {
        display: flex;
        align-items: center;
    }
}

.brand-grid-list {
    grid-template-columns: repeat( 6,1fr);
    grid-row-gap: 100px;
    display: grid;
    @media #{$lg-layout} {
        justify-content: center;
    }
    @media #{$md-layout} {
        justify-content: center;
    }
    @media #{$sm-layout} {
        grid-template-columns: repeat(4, 1fr);
        grid-row-gap: 30px;
        justify-content: center;
    }
    @media #{$large-mobile} {
        grid-template-columns: repeat(3, 1fr);
        grid-row-gap: 30px;
        justify-content: center;
    }
    @media #{$small-mobile} {
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 30px;
    }
    .brand {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}


.partner-style--1{
    grid-template-columns: repeat( 3,1fr);
    grid-row-gap: 100px;
    display: grid;
    align-items: center;

    @media #{$sm-layout} {
        grid-row-gap: 30px;
    }
    .thumb {
        transition: 0.5s;
    }
}



