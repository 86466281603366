/*=======================
    Tab Area 
========================*/


.tab-style--1{
    margin: 0;
    text-align: center;
    margin: -10px;
    margin-bottom: 50px;
    padding: 0;
    @media #{$sm-layout} {
        margin: -5px;
        margin-bottom: 30px;
    }
    li{
        font-size: 14px;
        font-weight: 500;
        line-height: 1.3;
        text-transform: uppercase;
        letter-spacing: 1px;
        border: 1px solid #ededed;
        background: #fff;
        color: #696969;
        padding: 21px 20px 19px;
        min-width: 200px;
        border-radius: 5px;
        margin: 10px;
        @extend %transition;
        outline: none;
        @media #{$sm-layout} {
            min-width: 140px;
            margin: 5px;
        }

        &:hover{
            color: #fff;
            background: #7c82fe;
            border-color: transparent;
        }
        &.react-tabs__tab--selected{
            color: #fff;
            background: #7c82fe;
            border-color: transparent;
        }
    }
}



/*=======================
Tab Style Two 
=========================*/
.tab-style--2 {
    .tab2-content {
        text-align: left;
        opacity: .5;
        @extend %transition;
        cursor: pointer;
        .title {
            display: flex;
            align-items: center;
            i{
                display: inline-block;
                width: 70px;
                height: 70px;
                line-height: 70px;
                text-align: center;
                font-size: 32px;
                border-radius: 15px;
                color: #fff;
                background-image: linear-gradient(-225deg, #f8c59b 0%, #e68c60 100%);
                box-shadow: 0 15px 50px rgba(89, 69, 230, 0.12);
                margin-right: 30px;
                @media #{$sm-layout} {
                    width: 63px;
                    height: 61px;
                    line-height: 61px;
                    font-size: 20px;
                    margin-right: 17px;
                }
            }
            h4 {
                flex-grow: 1;
                max-width: 226px;
                font-size: 24px;
                font-weight: 600;
                color: #fff;
                @media #{$sm-layout} {
                    font-size: 18px;
                }
            }
        }

        p {
            color: #fff;
            margin-top: 10px;
            margin-bottom: 0;
        }
    }

    &.text-gray{
        .tab2-content {

            .title {
                h4 {
                    color: #2e3280;
                }
            }
            p {
                color: #2e3280;
            }
        }
    }
}

.tab2-thumbnail-wrapper{
    .thumbnail {
        img{
            -moz-border-radius: 20px;
            -webkit-border-radius: 20px;
            border-radius: 20px;
        }
    }

    &.slick-dots-style {
        .slick-dots {
            bottom: -55px;
            @media #{$sm-layout} {
                bottom: -34px;
            }
            li {
                padding: 5px 4px;
                width: auto;
                height: auto;
                button {
                    position: relative;
                    margin: 0;
                    width: 16px;
                    height: 16px;
                    opacity: 1;
                    background: none;
                    border-radius: 100%;

                    &::before{
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        width: 8px;
                        height: 8px;
                        transform: translate(-50%, -50%);
                        border-radius: 50%;
                        background: #fff;
                        opacity: 1;
                        
                    }
                }
                &.slick-active {
                    button {
                        &::before {
                            background: #fff;
                            width: 16px;
                            height: 16px;
                        }
                    }
                }
            }
        }
    }
}

.text-gray{
    .slick-dots-style {
        .slick-dots {
            li {
                button {
                    &::before{
                        background: $theme-color;
                    }
                }
                &.slick-active {
                    button {
                        &::before {
                            background: $theme-color;
                        }
                    }
                }
            }
        }
    }
}


.tab-style--2{
    .slick-track {
        height: auto !important;
    }
}


.tab-style--2{
    .slick-center{
        .tab2-content{
            opacity: 1;
        }
    }
}



.startups-tabstwo-area {
    .tabs-three-area {
        padding-top: 0;
    }
}






