/*======================
    Blog Details 
========================*/

p:last-child {
    margin-bottom: 0;
}

.single-post-details {
    .thumbnail {
        margin-bottom: 48px;
        @media #{$sm-layout} {
            margin-bottom: 20px;
        }
    }
    .content {
        span {
            &.category{
                text-transform: uppercase;
                font-size: 14px;
                font-weight: 600;
                letter-spacing: 2px;
                margin-bottom: 9px;
                display: block;
                color: #b6b7d2;
                text-align: center;
            }
        }
        h2 {
            font-size: 34px;
            font-weight: 400;
            line-height: 1.42;
            margin: 0 0 16px;
            word-wrap: break-word;
            text-align: center;
            @media #{$sm-layout} {
                font-size: 28px;
                margin: 0 0 10px;
            }
        }
        .post-meta {
            font-size: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;

            .meta-info {
                display: flex;
                align-items: center;
                padding: 0 8px;
                img {
                    width: 32px;
                    vertical-align: middle;
                    margin-right: 6px;
                    border-radius: 50%;
                }
                .author {
                    margin-bottom: 0;
                    color: #7e7e7e;
                    font-weight: 400;
                }
            }
            .date {
                padding: 0 8px;
                span {
                    color: #7e7e7e;
                }
            }
        }
    }
    .content-wrapper {
        max-width: 800px;
        margin: 0 auto;
        .blog-description {
            p {
                margin-bottom: 1.5em;
                color: #7e7e7e;
            }
            blockquote{
                margin: 54px 65px 44px 70px;
                padding: 0 0 0 20px;
                font-size: 18px;
                font-style: italic;
                line-height: 1.78;
                border-left: 2px solid #eee;
                position: relative;
                color: #6d70a6;
                border-left-color: #38cb89;
                @media #{$sm-layout} {
                    margin: 30px;
                }
            }
        }

        .blog-post-share {
            display: flex;
            justify-content: space-between;
            padding-bottom: 20px;
            border-bottom: 1px solid #e4e8f6;
            margin-bottom: 50px;
            align-items: center;
            @media #{$sm-layout} {
                display: block;
            }
            .post-share-left {
                .tagcloud{
                    @extend %liststyle;
                    margin: -5px;
                    li{
                        display: inline-block;
                        padding: 5px;
                        a{
                            color: #ababab;
                            @extend %transition;
                            display: inline-block;
                            
                            &:hover{
                                color: $theme-color;
                            }
                        }
                    }
                }
            }
            .post-share-right {
                display: flex;
                align-items: center;
                span{
                    font-size: 14px;
                    margin-right: 8px;
                    color: #ababab;
                }
            }
        }
    }
    h4{
        &.post-title{
            margin-top: 66px;
            margin-bottom: 32px;
            font-size: 24px;
            font-weight: 400;
            color: #2e3280;
        }
    }
    .related-post-wrapper{
        .related-post{
            width: 400px;
            padding: 15px;
            height: 100%;
            background-color: #fff;
            border: 1px solid #e7e7e7;
            @media #{$sm-layout} {
                width: auto;
            }
            .inner{
                width: 100%;
                border-radius: 5px;
                overflow: hidden;
                padding: 85px 30px 43px;
                @media #{$sm-layout} {
                    padding: 20px;
                }
                span{
                    text-transform: uppercase;
                    letter-spacing: 2px;
                    font-size: 14px;
                    font-weight: 500;
                    color: #b6b7d2;
                }
                h3 {
                    margin: 6px 0 0;
                    font-size: 18px;
                    line-height: 1.5;
                    word-wrap: break-word;
                    color: #2e3280;
                }
            }
        }
    }
}


.blog-comment{
    .single-comment {
        display: flex;
        @media #{$sm-layout} {
            display: block;
        }
        &.CommentToComment{
            margin: 40px 0 20px 100px;
            padding: 0;
            @media #{$sm-layout} {
                margin: 40px 0 20px 20px;
            }
        }
        .comment-content {
            padding-left: 40px;
            @media #{$sm-layout} {
                padding-left: 0;
            }
            .heading {
                display: flex;
                align-items: center;
                margin-bottom: 12px;
                @media #{$sm-layout} {
                    display: block;
                }
                h5 {
                    font-size: 15px;
                    color: #333;
                    display: inline-block;
                    margin-bottom: 0;
                }
                span {
                    position: relative;
                    display: inline-block;
                    padding-left: 15px;
                    margin-left: 10px;
                    font-size: 14px;
                    font-style: italic;
                    color: #ababab;
                    @media #{$sm-layout} {
                        padding-left: 0;
                        margin-left: 0;
                    }
                }
            }
            .reply-button{
                margin-top: 16px;
                button{
                    font-weight: 600;
                    color: #6d70a6;
                    padding: 0;
                    margin: 0;
                    border: 0 none;
                    @extend %transition;
                    &:hover{
                        color: $theme-color;
                    }
                }
            }
        }
        & + .single-comment {
            margin-top: 40px;
            padding-top: 40px;
            border-top: 1px solid #ededed;
        }
    }
}

.comment-form-area {
    .input-box {
        input {
            color: #ababab;
            border-color: #f8f8f8;
            background-color: #f8f8f8;
            font-size: 15px;
            font-weight: 400;
            letter-spacing: 0em;
            width: 100%;
            outline: none;
            border: 1px solid #eee;
            border-radius: 5px;
            padding: 3px 20px;
            height: 56px;
            margin: 0 0 20px;
        }
        textarea {
            height: 200px;
            padding: 19px 20px 3px;
            width: 100%;
            outline: none;
            border: 1px solid #f8f8f8;
            border-radius: 5px;
            background: #f8f8f8;
            display: block;
            max-width: 100%;
        }
    }
}
