/*========================
    Download App Area 
========================== */


.download-app-wrapper{
    -webkit-box-shadow: 0 15px 40px rgba(89,69,230,0.05);
    box-shadow: 0 15px 40px rgba(89,69,230,0.05);
    background: url(/assets/images/mokup/aeroland-macbook-02.png) 55% 100% no-repeat,linear-gradient(-138deg,#5E60E7 0,#9C7AF2 50%,#5E60E7 100%);
    border-radius: 5px;
    .doenload-appinner{
        padding-left: 78px;
        @media #{$sm-layout} {
            padding-left: 30px;
        }
        .content {
            h3 {
                line-height: 1.88;
                color: #fff;
                font-size: 34px;
                font-weight: 600;
                letter-spacing: 0em;
                margin-bottom: 4px;
                @media #{$md-layout} {
                    line-height: 36px;
                    font-size: 25px;
                }
                @media #{$sm-layout} {
                    line-height: 36px;
                    font-size: 25px;
                }
            }
            h5 {
                line-height: 1.78;
                color: #fff;
                font-size: 18px;
            }
            a {
                &.download-btn {
                    margin-top: 20px;
                }
            }
        }
    }

    .thumbnail{
        margin-top: -50px;
        margin-bottom: -110px;
        margin-right: -77px;
        @media #{$lg-layout} {
            margin-right: 0;
        }
        @media #{$md-layout} {
            margin-top: 0;
            margin-bottom: 0;
            margin-right: 0;
        }
        @media #{$sm-layout} {
            margin-top: 0;
            margin-bottom: 0;
            margin-right: 0;
        }
        img{

        }
    }
}
